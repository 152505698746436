<template>
  <div class="mt-n12 ">

    <v-layout row  justify-space-between :class="$vuetify.breakpoint.lgAndUp?'ml-12 pl-12 mr-12 pr-12 mb-12' : 'ma-3 mt-10 ' ">
        <v-layout justify-center   column :style="$vuetify.breakpoint.mdAndUp?'width:50%':'width:90%'" class="mt-12 pt-5" >
            <h1 :style="$vuetify.breakpoint.mdAndUp?'font-size:36px; text-align: left;': 'font-size:36px; text-align: center;'">Friendly people, ready to help</h1><br>
            <p style="font-size: 22px; " :style="$vuetify.breakpoint.mdAndUp?'width: 80%;': 'text-align: justify;text-justify: inter-word;'"> Always humans, never bots. Contact us and we will get back to you within <span style="font-weight:700"> an hour. </span>
             You can look for your queries in our well written documentation as well. Feel free to check it out.  </p>
            <br>

            <v-row style="flex: 0" justify="center" justify-md="start"   >
              <v-col cols="10" md="3" lg="3" >
                <v-card rounded="lg" outlined  class="pa-2 pointer" hover>
                  <v-layout justify-center column> 
                  <v-icon large color="primary">description</v-icon> 
                  <p style="font-size:18px; font-weight: 400; text-align: center " class="mt-2 mb-n2">API Docs</p>
                  </v-layout>
                  </v-card>
              
              </v-col>
               <v-col cols="10" md="3" lg="3"  >
                <v-card rounded="lg" outlined  class="pa-2 pointer" hover>
                  <v-layout justify-center column> 
                  <v-icon large light color="primary">question_answer</v-icon> 
                  <p style="font-size:18px; font-weight: 400; text-align: center " class="mt-2 mb-n2">FAQs</p>
                  </v-layout>
                  </v-card>
              
              </v-col>
               <v-col cols="10" md="3" lg="3"  >
                <v-card rounded="lg" outlined  class="pa-2 pointer" hover>
                  <v-layout justify-center column> 
                  <v-icon large color="primary">local_library</v-icon> 
                  <p style="font-size:18px; font-weight: 400; text-align: center " class="mt-2 mb-n2">User Manual</p>
                  </v-layout>
                  </v-card>
              
              </v-col>
            
            </v-row>
        
        </v-layout>
        <v-layout justify-end style="width:40%;overflow: hidden;position:relative;" class=" mt-12 pt-10" v-show="$vuetify.breakpoint.mdAndUp" >
        <v-img :src="require('../assets/support.svg')" eager contain width="100%" max-height="500px"  aspect-ratio="1" style="float:right" ></v-img> 
        <!-- <v-img :src="require('../assets/support.svg')" contain width="100%" max-height="500px"  aspect-ratio="1" style="float:right" ></v-img>  -->


        <!-- <div id="box"></div> -->

        </v-layout>

        
    </v-layout>

    <v-layout justify-center align-center column :class="$vuetify.breakpoint.lgAndUp?'ml-12 pl-12 mr-12 pr-12 mb-12' : 'ma-3 mt-10 ' ">

         <h2 style="font-size:31px; text-align: center; font-weight: 500" class="mt-12">Still not getting what you’re looking for? Lets’s talk.</h2> <br>
             <p style="font-size: 20px; text-align: center;text-justify: inter-word; width:80% " :style="$vuetify.breakpoint.mdAndUp?'':'text-align:justify'" >We aren’t going to scam you with  thousands of e-mails. Send us a message, describing your issue, concerns, suggestions or anything. We’ll get back to you in <span style="font-weight: 700"> One Hour. </span>   </p>
            <br>
            <v-form :style="$vuetify.breakpoint.mdAndUp?'width: 60%': 'width:90%'">
              <v-select 
                  label="What can we help you with? *"
                  v-model="subject"
                  required
                  outlined
                  dense 
                ></v-select>
                <v-text-field
                  label="Your email address *"
                  v-model="email"
                  required
                  outlined
                  dense
                  ref="email"
                ></v-text-field>
                <v-textarea
                label="Tell us about your issues, problems, suggestions *"
                  v-model="matter"
                  required
                  outlined
                  dense
                  ref="matter"
                ></v-textarea>

                <v-layout justify-start>
                  <input id="fileUpload" type="file" hidden @change="getSelectedFile">

                  <p> Send us a screenshot, if any, for better undestanding of your problem. <v-btn small class="ml-1" color="secondary-white" @click="chooseFiles()">Choose file</v-btn> </p>
                  <v-chip v-if="attachment != null" class="ml-1" close color="secondary_white" @click:close="attachment = null">{{attachment.name}}</v-chip>
                </v-layout>
                <v-layout justify-center>
                  <v-btn   class="ma-5 loginbutton" color="primary" width="50%" >Submit</v-btn>
                </v-layout>

            </v-form>

              <h2 style="font-size:31px; text-align: center; font-weight: 500" class="mt-12">Come, meet us!</h2> <br>
              <!-- <v-card class="secondary_white mt-10" :width="$vuetify.breakpoint.mdAndUp?'40%':'90%'" rounded="lg">
                
                <v-layout justify-center align-center column style="width:100%" class="pa-5">
                   <h4 style="font-size:18px; font-weight: 700; text-align: center " class="mb-5">Company Details & address</h4>
                   
              <v-layout column align-start justify-center :style="$vuetify.breakpoint.mdAndUp?'width:50%':'width:100%'">
            <p style="font-size:18px;line-height:18px; font-weight: 400;text-align: left " >79 Ayer Rajah Crescent</p>
            <p style=" font-size:18px;line-height:18px; font-weight: 400;text-align: left" class="mt-n1">#04-01, NTUitive</p>
            <p style=" font-size:18px;line-height:18px; font-weight: 400; margin-bottom: 55px;text-align: left " class="mt-n1">Singapore 139955</p>
            </v-layout>


            <h4 style="font-size:18px; font-weight: 700; text-align: center " class="mb-5">Contact</h4>
            <v-layout column align-start justify-center :style="$vuetify.breakpoint.mdAndUp?'width:50%':'width:100%'">
            <p style=" font-size:18px;line-height:18px; font-weight: 400; text-align: center "><v-icon small left>call</v-icon> +65 698888708</p>
            <p style=" font-size:18px;line-height:18px; font-weight: 400; text-align: center " class="mt-n1"><v-icon small left>mail</v-icon> support@api.com</p>
            <p style="font-size:18px;line-height:18px; font-weight: 400; text-align: center " class="mt-n1"><v-icon small left>alternate_email</v-icon>xyz@email.com</p>
            </v-layout>

                </v-layout>
              </v-card> -->

              <lazyImage :src="require('../assets/Map.svg')"></lazyImage>


        </v-layout>

       


    
           

   

  </div>
</template>

<script>
const lazyImage = () => import('../components/lazyImage.vue')
export default {

  data: () => ({
    subject: '',
    matter: '',
    email: '',
    attachment: null,
  }),

  components: {
    lazyImage

  },

  methods: {
    chooseFiles() {
        document.getElementById("fileUpload").click()
    },

    getSelectedFile(e){
      this.attachment = e.target.files[0]
      console.log(this.attachment)
    }
    
    
    }

}
</script>

<style>

.pointer{
  cursor: pointer;
}

</style>